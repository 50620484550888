import React from 'react';
import './Footer.css'
import logoFooter from '../../assets/jyy-logo-white.png'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";
import footerBkg from '../../assets/footer-jjy-.png'




function Footer() {
  return(
    <>
    <div className="footer-before">
      <img src={footerBkg} alt="footer before" />
    </div>
    <footer id="footer">
  
      <div className="footer-section two">
      </div>
      <div className="footer-section 3">
      
      </div>
      <div className="creditos">
            <p>JYY GLOBAL - All Rights Reserved</p>
      </div>
      
    </footer>
    </>
  );
}

export default Footer;