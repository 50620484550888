import Diferencials from "../components/Diferencials/Diferencials"
import Header from "../components/Header/Header"
import ImagePlusText from "../components/ImagePlusText/ImagePlusText"
import Mosaic from "../components/Mosaic/Mosaic"
import videos from '../assets/video-banner-2.mov'
import { Link } from "react-router-dom"
import { FaSearchDollar } from "react-icons/fa";
import { TbMilitaryRank } from "react-icons/tb";
import { LiaGlobeAmericasSolid } from "react-icons/lia";
import { FaPeopleCarry } from "react-icons/fa";
import { FaTasks } from "react-icons/fa";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { BsBuildingFillCheck } from "react-icons/bs";
import { TbFreeRights } from "react-icons/tb";

import { BsHouse } from "react-icons/bs";
import { GoGraph } from "react-icons/go";
import { BsBuilding } from "react-icons/bs";
import { RiNewspaperLine } from "react-icons/ri";
import { BsHouseUp } from "react-icons/bs";
import { TbBriefcase } from "react-icons/tb";
import Footer from "../components/Footer/Footer"
import "./FrontPage.css"
import whiteLogo from '../assets/jyy-logo-white.png';


const FrontPage = () => {
    return(
        <>
        <img src={whiteLogo} className="novo-logo"/>
        <div className="front-page">
            <div className="banner">
                <video autoPlay loop muted playsinline>
                    <source src={videos} />
                </video>
                <div className="banner-text scroll-fade">
                    <h2>International<br />Investigation &<br />Risk Consulting</h2>
                    <Link to="/#section2"><button className="button-custom">Learn More</button></Link>
                </div>
            </div>
            <div className="about-us">
          
          <div className="about-us-text">

            <div className="textss" id='section2'>
                <div className="texts-container scroll-fade">
                  <p>JYY Global is a leading National and International investigation company offering a professional service to clients across a wide range of specialized fields. </p>
                  <p>Through our highly skilled and experienced personnel, consisting of former Security Services operatives, HMRC Investigators & New Scotland Yard detectives, we provide a level of investigative expertise which is unrivalled. </p>
                  <p>In addition, with access to sophisticated modern equipment and technology, it is no surprise that our results are excellent. </p>
                </div>
                
            </div>
            <h2 className='benefits-h2'>Benefits of JYY Global</h2>
            <div className="benefits">
            
            <div className="benefits-container">
                <div className="benefits-card scroll-fade">
                  <MdOutlineDashboardCustomize size={100}color='white'/>
                  <p>Bespoke Service</p>
                </div>
                <div className="benefits-card scroll-fade">
                  <FaSearchDollar size={100}color='white'/>
                  <p>35 Years of <br/>Investigative Experience</p>
                </div>
                
                <div className="benefits-card scroll-fade">
                  <TbMilitaryRank size={100}color='white'/>
                  <p>Ex Met Police Detectives<br/>Military Intelligence<br/>HMRC Personal</p>
                </div>
                <div className="benefits-card scroll-fade">
                  <LiaGlobeAmericasSolid size={100}color='white'/>
                  <p>Global Reach</p>
                </div>
                <div className="benefits-card scroll-fade" >
                  <FaTasks size={100}color='white'/>
                  <p>Reliable and<br/>Credibile Sources</p>
                </div>
                <div className="benefits-card scroll-fade">
                  <TbFreeRights size={100} color='white'/>
                  <p>Free Initial Consultations</p>
                </div>
                <div className="benefits-card scroll-fade">
                  <BsBuildingFillCheck size={100}color='white'/>
                  <p>Able to work under<br/>another companies brand</p>
                </div>
            </div>
          </div>
            <div className="withus">
                <h2 style={{textAlign: "center"}}>Who We Work With</h2>
                
            </div>
            <div className="withus-cards ">
                <div className="withus-card">
                    <BsHouse size={100} color='white'/> 
                    <h3>Family Offices</h3>
                </div>
                <div className="withus-card">
                    <GoGraph size={100} color='white'/> 
                    <h3>Hedge Funds</h3>
                </div>
                <div className="withus-card">
                    <BsBuilding size={100} color='white'/> 
                    <h3>Asset Management <br/>Companies</h3>
                </div>
                <div className="withus-card">
                    <RiNewspaperLine size={100} color='white'/> 
                    <h3>Litigation Funders</h3>
                </div>
                <div className="withus-card">
                    <BsHouseUp size={100} color='white'/> 
                    <h3>Boutique Solicitors</h3>
                </div>
                <div className="withus-card">
                    <TbBriefcase size={100} color='white'/> 
                    <h3>Insolvency Practitioners & <br/>HNW individuals</h3>
                </div>

            </div>
            <div className="contact-us">
              <h2>Contact Us</h2>
              <div className="contact-us-container">
                <div style={{width: 218}} className="contact-us-column">
                  <h3>Telephone</h3>
                  <a href="tel:02034885067">020 3488 5067</a>
                </div>
                <div className="contact-us-column">
                  <h3>Address</h3>
                  <a href="https://www.google.com/maps/place/67+Grosvenor+St,+London+W1K+3JN,+Reino+Unido/data=!4m2!3m1!1s0x4876052b851301a3:0x2166552c7e5daa14?sa=X&ved=1t:242&ictx=111" target="_blank">67 Grosvenor Street, Mayfair, London , W1K 3JN</a>
                </div>
                <div className="contact-us-column"> 
                  <h3>Email</h3>
                  <a href="mailto:jerry@jyyglobal.com">jerry@jyyglobal.com</a>
                </div>
                
              </div>
            </div>
                
          </div>
          
          <Footer />
        </div>
        </div>
        </>
        
    )
}

export default FrontPage